import React, { useContext } from "react";
import { NavLink } from "react-router-dom";
import Logo1 from "../../../assets/supportLogo1.svg";
import Logo2 from "../../../assets/supportLogo2.svg";
import Logo3 from "../../../assets/supportLogo3.png";
import "./Less/Footer.css";
import dogLogo from "../../../assets/logo.png";
import { DataContext } from "../../../contexts/Data.context";

interface IProps {}

const FooterView: React.FC<IProps> = (props) => {
    const { dogs } = useContext(DataContext);

    return (
        <section className="footer">
            <NavLink className="brand-section-f" to="/">
                <img src={dogLogo} alt="dog" className="brand-section-f__logo-f" />
                <h3 className="brand-section-f__title-f">A kilenc magyar kutya</h3>
            </NavLink>
            <ul className="footer-nav">
                {dogs
                    .sort((a, b) => (a.name.length > b.name.length ? 1 : -1))
                    .map((dog, index) => (
                        <li
                            key={`${dog.dog_id}-dog-link`}
                            className={`footer-nav__list-item footer-nav__list-item--${index + 1}`}
                        >
                            <NavLink className="no-textdecor" to={`/spec-dog/${dog.dog_id}`}>
                                {dog.name}
                            </NavLink>
                        </li>
                    ))}
            </ul>
            <div className="footer-bottom">
                <img className="footer-bottom__logo" src={Logo1} alt="Hungarikum Bizottság logo" />
                <img className="footer-bottom__logo" src={Logo2} alt="Agrárminisztérium logo" />
                <div className="footer-bottom__text">
                    Készült a Hungarikum Bizottság és az Agrárminisztérium támogatásával.
                </div>
                <img className="footer-bottom__logo" src={Logo3} alt="MEOESZ logo" />
                <div className="footer-bottom__text">
                    Minden jog fenntartva. MEOESZ, {new Date().getFullYear()}.
                </div>
            </div>
        </section>
    );
};

export default FooterView;
